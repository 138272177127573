@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('./swiper.css');

html {
  scroll-behavior: smooth;
}
body{
  font-family: "Lato";
}
.reveal{
  position: relative;
  transform: translateY(4rem);
  opacity: 0;
  transition: 1s all ease;
}

.reveal.active{
  transform: translateY(0);
  opacity: 1;
}

.slide-in-content.right{
  position: relative;
  transform: translateX(4rem);
  opacity: 0;
  transition: 1s all ease;
}
.slide-in-content.top{
  position: relative;
  transform: translatey(4rem);
  opacity: 0;
  transition: 1s all ease;
}
.slide-in-content.left{
  position: relative;
  transform: translateX(-4rem);
  opacity: 0;
  transition: 1s all ease;
}

.slide-in-content.active{
  transform: translateX(0);
  transform: translateY(0);
  opacity: 1;
}

@layer components{
  .container-website{
    @apply container relative;
  }
  .navbar-logo{
    @apply w-[80px] relative p-2 bg-dark-blue rounded-xl my-2;
  }
  .navbar-container{
    @apply w-full 
    shadow-md bg-white font-semibold text-dark-blue fixed top-0 px-5 h-auto z-40
  }
  .navbar-content{
    @apply flex relative flex-wrap justify-between items-stretch m-0 w-full p-0 h-full;
  }
  .navbar-menu{
    @apply flex justify-center hover:text-white
    cursor-pointer w-full h-full items-center 
    p-2 hover:bg-dark-blue transition delay-150 
    duration-300 ease-in-out font-normal;
  }
  /* .slide-in{
    @apply opacity-0 transition-all delay-75 translate-y-3
  }
  .slide-in.active{
    @apply opacity-100 transform translate-y-0 scale-50
  } */
} 